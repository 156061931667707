import SlimSelect from 'slim-select'

export default () => {
  const selects = document.querySelectorAll('.js-slim-select')

  selects.forEach((item) => {
    const firstOption = item.querySelector('option')
    if (firstOption) {
      firstOption.setAttribute('data-placeholder', 'true')
    }
    new SlimSelect({
      select: item,
      showSearch: false,
      onChange: () => {
        item.dispatchEvent(new Event('blur'))
        item.classList.add('is-selected')
        const field = item.closest('.field')
        if (field !== null) {
          field.classList.remove('field-error')
        }
      }
    })
    item.removeAttribute('style')
  })
}
